import {
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Input,
  Output,
  SimpleChanges,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { GlobalService } from '../../services/global.service';
import { Observable, Subject, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-number-input-template',
  templateUrl: './number-input-template.component.html',
  styleUrl: './number-input-template.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberInputTemplateComponent implements OnInit, OnChanges, OnDestroy {

  @Input() code: string;
  @Input() step: number;
  @Input() minimumQty: number;
  @Input() maximumQty: number;
  @Input() maxQtyLimit: number;
  @Input() value: number;
  @Input() isMaxLmtReached: Observable<boolean>;
  @Input() buttonName: string;

  @Output() addToCart = new EventEmitter<any>();
  unsubscribe$ = new Subject<void>();

  constructor(
    private storageService: StorageService,
    private globalService: GlobalService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    let availbleQty = this.getAvailableQty();
    this.maximumQty = availbleQty ? (this.maxQtyLimit - availbleQty) : this.maxQtyLimit;
    this.isMaxLmtReached = of(availbleQty >= this.maxQtyLimit);
  }

  getAvailableQty() {
    let cardDetails = this.storageService.activeCart;
    let productDetails = cardDetails?.entries?.filter(
      (item) => item.product.code === this.code
    );
    let quantity = productDetails?.at(0)?.quantity
      ? productDetails?.at(0)?.quantity
      : 0;
    return quantity;
  }

  updateQty(event: Event) {
    event.stopPropagation();
    let availbleQty = this.getAvailableQty();
    if (availbleQty) {
      this.maximumQty = availbleQty ? (this.maxQtyLimit - availbleQty) : this.maxQtyLimit;
      this.isMaxLmtReached = of(availbleQty >= this.maxQtyLimit);
    }
  }

  onBlur(event: Event) {
    event.stopPropagation();
    let availbleQty = this.getAvailableQty();
    if (this.value > (this.maxQtyLimit - availbleQty)) {
      this.value = availbleQty ? (this.maxQtyLimit - availbleQty) : this.maxQtyLimit;
    }
  }

  onAddToCart() {
    this.globalService.clearMessagesOnDestroy();
    let paramsObject = {
      code: this.code,
      quantity: this.value,
    };
    let requestObject = {
      product: {
        code: this.code,
      },
      quantity: this.value,
    };
    let cartId = this.storageService.cartId;
    this.globalService.loadingSubject.next(true);
    this.globalService
      .addToCart(cartId, paramsObject, requestObject)
      .subscribe({
        next: (data) => {
          this.globalService.getCartsListByLegalEntity();
          this.globalService.carts$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
              let availbleQty = this.getAvailableQty();
              this.isMaxLmtReached = of(availbleQty >= this.maxQtyLimit);
              this.cdRef.markForCheck();
            });
        },
        error: (error) => this.globalService.loadingSubject.next(false),
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
