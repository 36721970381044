<div *ngIf="referenceData$ | async as refData">
  <h4 *ngIf="refData?.length > 0">{{"productDetail.relatedProducts" |cxTranslate}}</h4>
  <div class="custom-flex-wrap" [ngClass]="{'mb-5': refData?.length > 0}">
    <ng-container *ngFor="let data of refData">
      <div class="mr-4 mb-3">
        <div class="card custom-flex-center">
          <div class="row">
            <a routerLink="{{getProductUrl(data?.target?.url)}}" class="col-md-4 custom-align-center">
              <img src="{{getImageUrl(data)}}" class="img-fluid card-img" alt="Related Product Image">
            </a>
            <div class="col-md-8">
              <div class="card-body">
                <a routerLink="{{getProductUrl(data?.target?.url)}}" class="cstm-anchor-primary">
                  {{data?.target?.code}}
                </a>
                <a routerLink="{{getProductUrl(data?.target?.url)}}" class="cstm-anchor-primary">
                  {{data?.target?.name}}
                </a>
                <div *ngIf="getBaseOrVariantOptions(data)?.quotationFlag; else displayPrice">
                  {{"productDetail.notAvailable"|cxTranslate}}
                </div>
                <ng-template #displayPrice>
                  {{(getBaseOrVariantOptions(data)?.priceData?.value)|currencyPrice}}
                  {{(getBaseOrVariantOptions(data)?.priceData?.currencyIso)|currencyFormat}}
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>