<div class="d-flex w-100">
    <input
        type="number"
        id="quantity"
        class="form-control"
        name="quantity"
        step="{{step}}"
        min="{{minimumQty}}"
        max="{{maximumQty}}"
        (input)="updateQty($event)"
        (blur)="onBlur($event)"
        [(ngModel)]="value"
        [disabled]="isMaxLmtReached | async"
    />
    <button type="button" class="cstm-btn-primary" (click)="onAddToCart()" [disabled]="isMaxLmtReached | async">
        <span>{{buttonName}}</span>
    </button>
</div>